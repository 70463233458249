<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create Attribute
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-product-list'}"
            >
              List Attribute
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div

            class="card"
          ><!----><!---->
            <div

              class="card-header"
            />
            <div

              class="card-body"
            ><!----><!---->
              <form

                class=""
              >
                <div

                  class="row"
                >
                  <div
                    class="col-12"
                  >
                    <div
                      role="group"
                      class="form-row form-group"
                    ><label
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Name</label>
                      <div class="col"><input
                        id="h-first-"
                        v-model="name"
                        type="text"
                        placeholder="Name"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Description</label>
                      <div class="col"><input
                        id="h-first-name"
                        v-model="description"
                        type="text"
                        placeholder="Description"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Price</label>
                      <div class="col"><input
                        id="h-first-name"
                        v-model="price"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                    ><label
                       id=""
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Length</label>
                      <div class="col"><input
                        id="h--name"
                        v-model="length"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                    ><label
                       id=""
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Height</label>
                      <div class="col"><input
                        id="h--name"
                        v-model="height"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                    ><label
                       id=""
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       weight</label>
                      <div class="col"><input
                        id="h--name"
                        v-model="weight"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                    ><label
                       id=""
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Width</label>
                      <div class="col"><input
                        id=""
                        v-model="width"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                    ><label
                       id=""
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Insurance Value</label>
                      <div class="col"><input
                        id=""
                        v-model="insurance_value"
                        type="number"
                        placeholder="Price"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div

                    class="offset-md-4 col"
                  >
                    <button

                      type="button"
                      class="btn mr-1 btn-primary"
                      @click.prevent="submitAddAttribute()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'

export default {

  name: 'ProductAddAttribute',
  data() {
    return {
      name: '',
      description: '',
      height: 0,
      length: 0,
      weight: 0,
      width: 0,
      insurance_value: 0,
      price: 0,
    }
  },
  created() {
    // this.getListProductCategory({})
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProductCategory']),
  },
  methods: {
    ...mapActions(['createAttribute']),
    submitAddAttribute() {
      const data = {
        name: this.name,
        description: this.description,
        // eslint-disable-next-line radix
        price: parseInt(this.price),
        // eslint-disable-next-line radix
        height: parseInt(this.height),
        // eslint-disable-next-line radix
        length: parseInt(this.length),
        // eslint-disable-next-line radix
        weight: parseInt(this.weight),
        // eslint-disable-next-line radix
        width: parseInt(this.width),
        // eslint-disable-next-line radix
        insurance_value: parseInt(this.insurance_value),
        status: 1,
        product_id: this.$route.params.id,
      }
      this.createAttribute(data)
    },
  },
}
</script>

<style scoped>

</style>
